.signInButton {
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signInButton__button {
    padding: 4px;
    margin: 8px 0 0 0;
}