.App {
  text-align: center;
  background: #f7f7f7;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.success{
  color:#107c10
}

.error{
  color:#a80000
}

.info{
  color:rgb(0, 120, 212);
}

.hide {
  display: none;
}

.fade-in {
  display: block;
  opacity: 0;
  height: 0;
  animation: element-fade-in 120ms linear;
  animation-delay: 85ms;
  animation-fill-mode: forwards;
}

@keyframes element-fade-in {
  0% {
    height: 0;
  }

  1% {
    height: auto;
    opacity: 0;
  }

  100% {
    height: auto;
    opacity: 1;
  }
}