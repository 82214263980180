body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow-y: hidden;
}

::selection {
  color: #fff;
  background: #0055a4 !important;
}